import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const Grid = makeShortcode("Grid");
const Paper = makeShortcode("Paper");
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "your-teenager-and-friendships"
    }}>{`Your teenager and friendships`}</h1>
    <p>{`We all want our teenagers to have friendships with people that they find supportive and considerate. To develop such friendships, teenagers need good social skills. Having these skills makes it easier to make friends and also keep good friends. Below are the topics we will cover in this module.`}</p>
    <TextCustom customColor="blue" variant="h5" mdxType="TextCustom">
  Encourage good friendships
    </TextCustom>
    <Grid container spacing={2} mdxType="Grid">
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Range of social activities
      </TextCustom>
      <Img src="/images/m6/13.svg" alt="social" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Range of people of different ages
      </TextCustom>
      <Img src="/images/m6/12.svg" alt="peope" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Good social skills
      </TextCustom>
      <Img src="/images/m6/11.svg" alt="skill" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Talking through friendship problems
      </TextCustom>
      <Img src="/images/m6/14.svg" alt="frienship" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Your role
      </TextCustom>
      <Img src="/images/m6/15.svg" alt="role" mdxType="Img" />
    </Paper>
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      